<template>
	<div
		v-if="highlightbox"
		:class="highlightbox.highlightboxClasses"
	>

		<media
			:query="{minWidth: 992}"
			v-if="highlightbox.parallaxImage"
		>

			<div
				v-if="highlightbox.parallaxImage"
				class="fullwidth-container--background-image--container"
				:class="highlightbox.parallaxImage"
				:style="{backgroundImage: `url(${highlightbox.image})`}"
				v-prlx.background="{ speed: 0.02 }"
			></div>

		</media>

		<div
			v-else
			class="fullwidth-container--background-image--container"
			:style="{backgroundImage: `url(${highlightbox.image})`}"
		></div>

		<media
			:query="{maxWidth: 991}"
			v-if="highlightbox.mobileImg"
		>

			<div
				class="fullwidth-container--background-image--container"
				:class="highlightbox.parallaxImage"
				:style="{backgroundImage: `url(${highlightbox.mobileImg})`}"
				v-prlx.mobile
			></div>

		</media>
		<div
			v-else
			class="fullwidth-container--background-image--container"
			:style="{backgroundImage: `url(${highlightbox.mobileImg})`}"
			v-prlx.mobile
		></div>

		<div class="highlight-box fullwidth-container">
			<div
				:class="`${highlightbox.contentWidth} page--wrapper`"
			>
				<div
					class="container"
					:style="highlightbox.contentStyle"
					v-if="highlightbox.content"
				>
					<ce-renderer
						:content="content"
					/>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import Media from 'vue-media';
export default {
	name: 'HighlightBox',
	components: {
		Media
	},
	props: {
		// @TODO: Rewrite this component to use actual properties and not use
		// one single object where the configuration will be set somehow with
		// possible random data!
		highlightbox: {
			type: Object
		},
		mobile_image: {
			type: Object
		},
		content: {
			type: Array
		},
		content_animation: {
			type: String,
			required: false,
			default: ''
		},
		parallaxImage: {
			type: Boolean,
			required: false,
			default: false
		},
	}
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$button--color: contrast-color(color(primary, base)) !default;
	$button--background-color: color(primary, base) !default;
	$button--hover--background-color: color(primary, dark) !default;
	$button--hover--color: contrast-color(color(primary, base)) !default;
	$button--hover--border-color: $button--hover--color !default;

	$highlight-box--colors: (
		primary: (
			_background: color(primary, base),
			_color: contrast-color(color(primary, base)),
			_headline-color: contrast-color(color(primary, base)),
			_link-color: contrast-color(color(primary, base)),
			_link-color--hover: contrast-color(color(primary, base)),
			_button-styles: (
				btn: (
					_background: contrast-color(color(primary, base)),
					color: color(primary, base),
					border: 1px solid contrast-color(color(primary, base)),

					_hover: (
						background: $button--hover--background-color,
						color: $button--hover--color,
						border-color: $button--hover--background-color,
					),
				),
			),
		),
		secondary: (
			_background: color(secondary, base),
			_color: contrast-color(color(secondary, dark)),
			_headline-color: contrast-color(color(secondary, dark)),
			_link-color: contrast-color(color(secondary, dark)),
			_link-color--hover: contrast-color(color(secondary, dark)),
			_button-styles: (
				btn: (
					background: contrast-color(color(secondary, base)),
					color: color(secondary, base),
					border: 1px solid contrast-color(color(secondary, base)),

					_hover: (
						background: $button--hover--background-color,
						color: $button--hover--color,
						border-color: $button--hover--background-color,
					),
				),
			),
		),
		tertiary: (
			_background: color(secondary, dark),
			_color: contrast-color(color(secondary, dark)),
			_headline-color: contrast-color(color(secondary, dark)),
			_link-color: contrast-color(color(secondary, dark)),
			_link-color--hover: contrast-color(color(secondary, dark)),
			_button-styles: (
				btn: (
					background: contrast-color(color(secondary, base)),
					color: color(secondary, base),
					border: 1px solid contrast-color(color(secondary, base)),

					_hover: (
						background: $button--hover--background-color,
						color: $button--hover--color,
						border-color: $button--hover--background-color,
					),
				),
			),
		),
		grey: (
			_background: color(grey, light),
			_color: contrast-color(color(grey, light)),
			_headline-color: contrast-color(color(grey, light)),
			_link-color: contrast-color(color(grey, light)),
			_link-color--hover: contrast-color(color(grey, light)),
		),
	) !default;

	$highlight-box--inner--padding: rem(60) !default;
	$highlight-box--min-height: rem(480) !default;
	$highlight-box--vertical-padding: $highlight-box--inner--padding !default;
	$highlight-box--horizontal-padding: $highlight-box--inner--padding !default;
	$highlight-box--margin: 0 0 40px !default;

	$fullwidth-container--margin-bottom: 0 !default;
	$fullwidth-container--inner--max-width: $container-max-widths !default;

	$highlight-box--gradient--background:
	linear-gradient(
		to bottom,
		color(primary),
		color(secondary)
		) !default;

	.highlight-box {
		position: relative;
		// overflow: hidden;

		&:not(.fullwidth-container) {
			margin: $highlight-box--margin;
		}
	}


	.highlight-box--inner {
		padding-top: 1px;
		padding-bottom: 1px;

		&.fullwidth-container--no-padding {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.highlight-box--inner-wrapper {
		margin-top: $highlight-box--vertical-padding;
		margin-bottom: $highlight-box--vertical-padding;

		.highlight-box--no-vertical-padding > & {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.highlight-box .fullwidth-container--inner {
		margin-top: $highlight-box--vertical-padding;
		margin-bottom: $highlight-box--vertical-padding;

		padding-top: 0;
		padding-bottom: 0;

		@include media-breakpoint-up(md) {
			padding: 0 50px;
		}
	}

	.highlight-box--image .frame-default {
		padding: 0;
	}

	// only is highlight-box--image
	.highlight-box--image  {
		.highlight-box {
			padding: 20px 0 20px 0;

			.page--wrapper {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}
	}

	.highlight-box--image .has-overlay {

		color: color(white);

		h1,h2,h3,h4,h5,h6,
		.h1,.h2,.h3,.h4,.h5,.h6 {
			color: color(white);
		}

		a:not(.button) {
			color: color(white);

			&:hover,
			&:focus {
				color: color(white);
			}
		}

		.fullwidth-container--background-image--container {
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background: rgba(color(black), 0.5);
				z-index: 0;
				}
			}
	}

	.highlight-box--center {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: none;
	}

	@each $_className,
	$_conf in $highlight-box--colors {

		#{'.highlight-box--' + $_className } {

			height: 100%;

			background: map-get($_conf, _background);
			color: map-get($_conf, _color);

			.highlight-box--background {
				background: map-get($_conf, _background);
			}

			.highlight-box--inner {
				color: map-get($_conf, _color);
			}

			$_headline-color: map-get($_conf, _headline-color);

			@if ($_headline-color) {

				h1,h2,h3,h4,h5,h6,
				.h1,.h2,.h3,.h4,.h5,.h6 {
					color: $_headline-color;
				}
			}

			$_link-color: map-get($_conf, _link-color);
			$_link-color--hover: map-get($_conf, _link-color--hover);

			@if ($_link-color) {
				a {
					color: $_link-color;

					@if ($_link-color--hover) {

						&:hover,
						&:focus {
							color: map-get($_conf, _link-color--hover);
						}
					}
				}
			}

			button,
			.btn {
				color: $button--color;
				border: 1px solid contrast-color(color(primary, base));

				&:hover,
				&:focus {
					color: $button--hover--color;
				}

				$button-styles: map-get($_conf, _button-styles);

				@if type-of($button-styles)=="map" {
					background: map-get($_conf, _background);
				}

			}

			&.fullwidth-container .fullwidth-container--content {
				background: map-get($_conf, background);
				width: 100%; // for IE11
			}
		}
	}

	// fullwidth-container
	.fullwidth-container {
		position: relative;
		left: 50%;
		margin-left: -50vw;
		margin-bottom: $fullwidth-container--margin-bottom;
		width: 100vw;
	}

	.fullwidth-container .container {
		padding: 0;
	}

	.fullwidth-container.fp-section.fp-table {
		width: 100vw;
	}

	.fullwidth-container--background-image--container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		// background-position: 50% 50%;
		background-size: cover;

		&.parallax-img {
			background-size: inherit;
			background-repeat: no-repeat;

			@include media-breakpoint-up(lg) {
				transition: 2s cubic-bezier(0, 0, 0, 1) 0s!important;
			}

			// @include media-breakpoint-down(sm) {
			// 	background-repeat: repeat;
			// }
		}

		* {
			width: 100%;
			height: 100%;
		}

		picture {
			display: block;
		}

		img {

			object-fit: cover;
			font-family: 'object-fit: cover;';

			user-select: none;
			pointer-events: none;
			z-index: 1;
			border-radius: 0;
			object-position: center top;
		}

	}


	.fullwidth-container > .row {
		margin: 0;
	}

	.fullwidth-container--content--container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;

		.fullwidth-container--image--container + & {
			align-items: flex-start;
		}
	}

	.fullwidth-container--content {
		margin: 0;
	}

	.fullwidth-container--image--container img {
		width: 100%;
	}

	@include media-breakpoint-down(sm) {
		.fullwidth-container--image--container {
			text-align: right;

			.fullwidth-container--content--container + & {
				text-align: left;
			}
		}
	}

	.fullwidth-container--inner {
		position: relative;
		padding-top: 1px;
		padding-bottom: 1px;

	}

	.fullwidth-container--inner-wrapper {
		margin: 40px 0;
	}

	.fullwidth-container .fsc-container > .row > .col {

		dl, menu, ol, p, pre, ul {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
</style>
